import { Admin, Resource, ListGuesser, ShowGuesser } from 'react-admin';
import crudProvider from '@fusionworks/ra-data-nest-crud';
import UserIcon from "@mui/icons-material/Group";
import RadioIcon from '@mui/icons-material/Radio';

import { VerificationList } from './verification';
import { DeploymentCreate, DeploymentEdit, DeploymentList } from './deployment';

const dataProvider = crudProvider('/api');
export function App() {
  return (
    <Admin dataProvider={dataProvider}>
      {/* <Resource name="radio-stations" list={ListGuesser} /> */}
      <Resource icon={UserIcon} hasEdit hasCreate name="deployments" list={DeploymentList} show={ShowGuesser} edit={DeploymentEdit} create={DeploymentCreate} />
      <Resource icon={RadioIcon} name="verification" list={VerificationList} />
    </Admin>
  );
}

export default App;
