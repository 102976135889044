import {
  Create,
  SimpleForm,
  TextInput,
  Edit,
  required,
  TextField,
  Show,
  SimpleShowLayout,
  List,
  Datagrid,
} from 'react-admin';

const validateRequired = required();
const validateRequiredJson = [required(), (value) => {
  try {
    JSON.parse(value);
  } catch (e) {
    return 'Invalid JSON';
  }
  return undefined;
}];

export const DeploymentCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={validateRequired} />
      <TextInput source="ansibleVars" multiline validate={validateRequiredJson} />
    </SimpleForm>
  </Create>
);

export const DeploymentEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" validate={validateRequired} />
      <TextInput source="ansibleVars" multiline validate={validateRequiredJson} />
    </SimpleForm>
  </Edit>
);

export const DeploymentShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="ansibleVars" />
    </SimpleShowLayout>
  </Show>
);

export const DeploymentList = () => (
    <List>
        <Datagrid>
            <TextField source="name" />
            <TextField source="createdAt" />
            <TextField source="ansibleVars" />
        </Datagrid>
    </List>
);
